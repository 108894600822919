function debounce(fn, wait) {
  let t;
  return (...args) => {
    clearTimeout(t);
    t = setTimeout(() => fn.apply(this, args), wait);
  };
}

function outerHeight(el) {
  const style = getComputedStyle(el);

  return (
    el.getBoundingClientRect().height +
    parseFloat(style.marginTop) +
    parseFloat(style.marginBottom)
  );
}

function isVisible(el) {
  return !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length);
}

function kebabToCamel(kebabString) {
  return kebabString.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
}

const handleUpButton = (() => {
  const upButton = document.getElementById('upButton');
  if(!upButton) return;

  const handleScroll = () => {
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if(scrollTop > viewportHeight) {
      upButton.classList.add('is-active');
    } else {
      upButton.classList.remove('is-active');
    }
  }

  upButton.addEventListener('click', () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  });

  window.addEventListener('scroll', debounce(handleScroll, 200));
  
})();

class FileInput extends HTMLElement {
  constructor() {
    super();
    this.input = this.querySelector("input[type=file]");
    this.label = this.querySelector("label");
    this.text = this.label.querySelector('.is-input');
    this.defaultLabelText = this.text.textContent;
    this.changeEvent = new Event('change', { bubbles: true });
    this.input.addEventListener('change', this.onInputChange.bind(this));
  }

  onInputChange(event) {
    const curFiles = this.input.files;
    if (curFiles.length === 0) {
      this.text.textContent = this.defaultLabelText;
    } else {
      let filesNamesList = '';
      for (const file of curFiles) {
        filesNamesList += `${file.name}\n`;
      }
      this.text.textContent = `Загружено файлов: ${curFiles.length}`;
      this.label.setAttribute('title', filesNamesList)
    }
  }
}

customElements.define('file-input', FileInput);

class MenuDrawer extends HTMLElement {
  constructor() {
    super();
  }
}

customElements.define('menu-drawer', MenuDrawer);

class HeaderDrawer extends MenuDrawer {
  constructor() {
    super();

    const toggleMenuButton = this.querySelector('#toggleMenu');
    const menuDrawer = document.querySelector('.menu-drawer');
    const header = document.querySelector('.is-header');

    header.style.setProperty("width", `calc(100vw - ${window.innerWidth - document.scrollingElement.clientWidth}px)`);

    toggleMenuButton.addEventListener('click', () => {
      menuDrawer.classList.toggle('menu-opened');
      header.classList.toggle('menu-opened');
      document.body.classList.toggle('lock-scroll');

      let scrollEl = document.scrollingElement;
      scrollEl.style.getPropertyValue("overflow-y") === '' ?
      scrollEl.style.setProperty("overflow-y", "scroll") :
      scrollEl.style.removeProperty("overflow-y");
    });

    menuDrawer.addEventListener('click', (event) => {
      if (event.target === menuDrawer) {
        menuDrawer.classList.remove('menu-opened');
        header.classList.remove('menu-opened');
        document.body.classList.remove('lock-scroll');
        document.scrollingElement.style.removeProperty('overflow-y');
      }
    });
  }
}

customElements.define('header-drawer', HeaderDrawer);

class NavGroup extends HTMLElement {
  constructor() {
    super();

    this.initialized = false;

    this.handleResize = this.handleResize.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  connectedCallback() {
  	this.openBtn = this.querySelector('.main-nav__toggle-btn');
    this.menuContainer = this.querySelector('.main-nav__menu-container');
    this.menuList = this.querySelector('.main-nav__menu');

    if (this.openBtn === null || this.menuContainer === null || this.menuList === null) {
		  return;
		}
		if (window.matchMedia('(max-width: 768px)').matches) {
			this.initializeCollapsedNav();
		}
    window.addEventListener('resize', debounce(this.handleResize, 100));
  }

  initializeCollapsedNav() {
    this.menuContainer.style.height = 0;
    this.menuContainer.style.overflow = 'hidden';

    this.openBtn.addEventListener('click', this.handleButtonClick);

    this.initialized = true;
  }

  updateCollapsedNav() {
    if(this.classList.contains('is-open')) {
			this.menuContainer.style.height = `${outerHeight(this.menuList)}px`;
		}
  }

  destroyCollapsedNav() {
    this.menuContainer.removeAttribute('style');
    this.classList.remove('is-open');
    this.openBtn.removeEventListener('click', this.handleButtonClick);

    this.initialized = false;
  }

  handleButtonClick() {
  	this.classList.toggle('is-open');

  	setTimeout(() => {
	    this.toggleMenu();
	  },1);
  }

	toggleMenu() {
		if(this.classList.contains('is-open')) {
			this.menuContainer.style.height = `${outerHeight(this.menuList)}px`;
		} else {
			this.menuContainer.style.height = 0;
		}
	}

  handleResize() {
    if (window.matchMedia('(max-width: 768px)').matches) {
      if (this.initialized) {
        this.updateCollapsedNav();
      } else {
        this.initializeCollapsedNav();
      }
    } else {
      if (this.initialized) {
        this.destroyCollapsedNav();
      }
    }
  }
}
customElements.define('nav-group', NavGroup);

class ModalDialog extends HTMLElement {
  constructor() {
    super();
    this.querySelector('.is-modal__close').addEventListener('click', this.hide.bind(this, false));
    this.addEventListener('keyup', (event) => {
      if (event.code.toUpperCase() === 'ESCAPE') this.hide();
    });
    this.addEventListener('click', (event) => {
      if (event.target === this) this.hide();
    });
  }

  show() {
    document.body.classList.add('lock-scroll');
    let scrollEl = document.scrollingElement;
    scrollEl.style.getPropertyValue("overflow-y") === '' ? 
    scrollEl.style.setProperty("overflow-y", "scroll") : 
    scrollEl.style.removeProperty("overflow-y");
    this.setAttribute('open', '');
  }

  hide() {
    document.body.classList.remove('lock-scroll');
    document.scrollingElement.style.removeProperty('overflow-y');
    document.body.dispatchEvent(new CustomEvent('modalClosed'));
    this.removeAttribute('open');
  }
}
customElements.define('modal-dialog', ModalDialog);

const globalApp = {
  init: function() {
    this.executeModules();
  },
  executeModules: function(parent) {
    parent = parent || document.body;
    const mods = parent.querySelectorAll('[data-is]');
    mods.forEach( (thisModule, i) => {
      const thisModuleName = thisModule.getAttribute('data-is');

      if (moduleApp[thisModuleName]) {
        console.log(`%cRun: '${thisModuleName}' - module executed.`, 'color:#009900;');
        moduleApp[thisModuleName](thisModule);
      } else {
        console.log(`%cError: '${thisModuleName}' - module not defined.`, 'color:#ff6347;');
      }
    });
  }
}

const moduleApp = {
  'form-validation': function(thisModule, thisCallback) {
    thisCallback = thisCallback || false;

    const params = {
      "fields": {
        "attr": "validation",
        "parentClass": "is-form-field",
        "parentErrorState": "state-error",
        "errorNode": "ff-error-parent",
        "formFocusClass": "state-focus",
        "formHasValueClass": "state-has-value",
        "fromInitedClass": "state-inited",
        "fieldInitidClass": "_field-inited",
        "initialNodes": null

      },
      "form": {
        "moduleParent": thisModule,
        "parent": thisModule.querySelector('form'),
        "submitLinkClass": "js-form-submit",
        "validFormClass": "form-state-valid",
        "invalidFormClass": "form-state-invalid",
        "progressFormClass": "form-state-progress",
        "progressButtonClass": "button-state-progress"
      },
      "inner": {
        "submitCallback": thisCallback,
        "realTimeCheck": false,
        "defaultFieldData": {
          "mask": "none",
          "require": true,
          "visible": true,
          "error": "",
          "cleaveMask": false
        }
      }
    };

    const methods = {
      init: function() {
        methods.initFields();
        methods.initEvents();
        methods.initFieldsMarkup();
        methods.initFormStatus();
      },
      initFields: function() {
        params.fields.initialNodes = params.form.parent.querySelectorAll(`[data-${params.fields.attr}]`)
      },
      initEvents: function() {
        params.form.parent.addEventListener('submit', methods.eventFormSubmit, false);
        params.form.parent.querySelector(`.${params.form.submitLinkClass}`).addEventListener('click', methods.eventSubmitLinkClick, false);

        const fields = params.form.moduleParent.querySelectorAll(`[data-${params.fields.attr}]`);
        fields.forEach(field => {
          ['keypress', 'input', 'change'].forEach(eventType => {
            field.addEventListener(eventType, methods.eventChangeFields, false);
          });
          field.addEventListener('keydown', methods.eventFormSubmitEnter, false);
          field.addEventListener('focus', methods.eventFocusEnter, false);
          field.addEventListener('blur', methods.eventFocusLeave, false);
        });
      },
      initFieldsMarkup: function() {
        params.form.parent.querySelectorAll(`[data-${params.fields.attr}]:not(.${params.fields.fieldInitidClass})`).forEach((thisField, i) => {
          try {
            const thisFieldDataRaw = JSON.parse(thisField.dataset[kebabToCamel(params.fields.attr)]);
            const thisFieldData = {
              ...params.inner.defaultFieldData,
              ...thisFieldDataRaw
            };
            // error message
            if(thisFieldData.error) {
              const errorNode = document.createElement('div');
              errorNode.classList.add(params.fields.errorNode)
              errorNode.innerHTML = thisFieldData.error
              thisField.closest(`.${params.fields.parentClass}`).append(errorNode);
            }

            // masked input
            if(thisFieldData.cleaveMask) new Cleave(thisField, {phone:true,prefix:'+7',noImmediatePrefix:true,phoneRegionCode:'RU'});

            thisField.classList.add(params.fields.fieldInitidClass);
            thisField.closest(`.${params.fields.parentClass}`).classList.add(params.fields.fromInitedClass);
          } catch(e) {
            console.error(e.name);
            console.error(e.message)
          }
        });
      },
      initFormStatus: function() {
        if(methods.checkingFieldsAll(params.fields.initialNodes,true)) {
          params.form.moduleParent.classList.add(params.form.validFormClass);
          params.form.moduleParent.classList.remove(params.form.invalidFormClass);
        } else {
          params.form.moduleParent.classList.remove(params.form.validFormClass);
          params.form.moduleParent.classList.add(params.form.invalidFormClass);
        }
      },
      eventFormSubmit: function() {
        methods.initFields();
        methods.initFieldsMarkup();
        methods.initFormStatus();

        // if form in progress
        if (params.form.moduleParent.classList.contains(params.form.progressFormClass)) { return false; }

        // enable real time checking
        params.inner.realTimeCheck = true;

        // return state - is an error state
        // if `FALSE` - fields has an error and submit is prevent
        // if `TRUE` - no error and form going submit
        const returnState = methods.checkingFieldsAll(params.fields.initialNodes);

        // if fields valid then add progress state to form

        if (returnState == true) {
          params.form.moduleParent.classList.add(params.form.progressFormClass);
          params.form.moduleParent.querySelector(`.${params.form.submitLinkClass}`).disabled = true;
          params.form.moduleParent.querySelector(`.${params.form.submitLinkClass}`).classList.add(params.form.progressButtonClass)
        }

        // if submit callback
        if (returnState && params.inner.submitCallback) {
          params.inner.submitCallback();
          return false;
        }

        return returnState;

      },
      eventSubmitLinkClick: function(e) {
        e.preventDefault();

        params.inner.submitCallback = function() {
          const formData = new FormData(params.form.parent);
          fetch(params.form.parent.getAttribute('action'), {
            method: 'POST',
            body: formData
          })
          .then(response => response.json())
          .then(data => {

            // Custom Popup modal
            // const modal = document.getElementById('FormModal');
            // modal.querySelector('.is-modal__body').innerHTML = `<h3 class="heading heading--sm">${data.title}</h3><p>${data.message}</p>`;
            // modal.show();

            console.log(data);
            if (data.status === 'success') {
              if (typeof BX !== 'undefined') {
                BX.ready(() => {
                  BX.Runtime.loadExtension('ui.notification').then(() => {
                    BX.UI.Notification.Center.notify({
                      content: `
                        <div class="info-message">
                          <div class="info-message__container">
                            <img class="info-message__img" src="/img/PixDone.svg" alt="PixDone">
                            <div class="heading info-message__text">Ваша заявка успешно отправлена!</div>
                          </div>
                        </div>`,
                      data: { type: 'danger', icon: 'warning', size: 'xs' },
                      position: 'top-center',
                      autoHideDelay: 3000,
                      width: '100%',
                      render: function () {
                        const content = this.getContent();

                        return BX.create("div", {
                          props: {
                            className: ''
                          },
                          /* style: {
                              width: BX.type.isNumber(width) ? (width + "px") : width
                          }, */
                          children: [
                            BX.create("span", {
                              props: {
                                className: "ui-alert-message"
                              },
                              html: content
                            })
                          ]
                        });
                      }
                    });
                  });
                });
              } else {
                console.error("BX is not defined.");
              }
            }

            params.form.parent.reset();
            params.form.moduleParent.classList.remove(params.form.progressFormClass);
            params.form.moduleParent.querySelector(`.${params.form.submitLinkClass}`).disabled = false;
            params.form.moduleParent.querySelector(`.${params.form.submitLinkClass}`).classList.remove(params.form.progressButtonClass);
          })
          .catch(error => {
            console.error('Error:', error);
          });
        };

        methods.eventFormSubmit();
      },
      eventChangeFields: function() {
        const thisField = this;
        try {
          const thisFieldDataRaw = JSON.parse(thisField.dataset[kebabToCamel(params.fields.attr)]);
          const thisFieldData = {
            ...thisFieldDataRaw,
            ...{thisField:thisField,thisValue:thisField.value.trim()}
          };

          // checking this field
          if(params.inner.realTimeCheck) { methods.checkingField(thisField); }

          methods.initFormStatus();

        } catch(e) {
          console.error(e.name);
          console.error(e.message);
        }
      },
      checkingField: function(thisField,hiddenChecking = false) {
        let fieldStatus = true;

        try {
          const thisFieldDataRaw = JSON.parse(thisField.dataset[kebabToCamel(params.fields.attr)]);
          const thisFieldData = {
            ...params.inner.defaultFieldData,
            ...thisFieldDataRaw,
            ...{thisField:thisField,thisValue:thisField.value.trim()}
          };

          // checking empty value
          if(thisField.value.length === 0) {
            thisField.closest(`.${params.fields.parentClass}`).classList.remove(params.fields.formHasValueClass);
          } else {
            thisField.closest(`.${params.fields.parentClass}`).classList.add(params.fields.formHasValueClass);
          }

          // checking for mask
          if (methods.checkingMasks[thisFieldData.mask]) {
            if (methods.checkingMasks[thisFieldData.mask](thisFieldData) || methods.checkingMasks['visibleAndRequire'](thisFieldData)) {
              // remove error class
              if (!hiddenChecking) {
                thisField.closest(`.${params.fields.parentClass}`).classList.remove(params.fields.parentErrorState);
              }
            } else {
              // added error class
              if (!hiddenChecking) {
                thisField.closest(`.${params.fields.parentClass}`).classList.add(params.fields.parentErrorState);
              }
              // added error status
              fieldStatus = false;
            }
          }

          return fieldStatus;

        } catch(e) {
          console.error(e.name);
          console.error(e.message);
        }
      },
      checkingFieldsAll: function(fields,hiddenChecking = false) {
        const checkingParams = {
          "status": true,
          "focusFlag": true
        };

        fields.forEach((thisField, i) => {
          const thisFieldStatus = methods.checkingField(thisField,hiddenChecking);
          if (!thisFieldStatus) {
            checkingParams.status = false;

            if (checkingParams.focusFlag && !hiddenChecking) {
              checkingParams.focusFlag = false;
              thisField.focus();
            }
          }
        });
        return checkingParams.status;
      },
      checkingMasks: {
        text: (data) => data.thisValue.length > 0,
        phone: (data) => data.thisValue.length === 16,
        email: (data) => (/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(data.thisValue)),
        visibleAndRequire: function(data) {
          // invisible field
          if (data.visible && !isVisible(data.thisField.closest(`.${params.fields.parentClass}`))) { return true; }

          // not require field
          if (!data.require && data.thisValue.length === 0) { return true; }

          // return default
          return false;
        }
      },
      eventFormSubmitEnter: function(e) {
        if (e.keyCode == 13) { methods.eventFormSubmit(); return false; }
        return true;
      },
      eventFocusEnter: function() {
        this.closest(`.${params.fields.parentClass}`).classList.add(params.fields.formFocusClass);
      },
      eventFocusLeave: function() {
        this.closest(`.${params.fields.parentClass}`).classList.remove(params.fields.formFocusClass);
      },
    };

    methods.init();
  }
}

document.addEventListener('DOMContentLoaded', () => {
  globalApp.init();
});
